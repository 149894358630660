$icon-font-path: '../fonts/';

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* Space out content a bit */
body {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
{
  padding-left: 16px;
  padding-right: 16px;
}

/* Custom page header */
.header {
  border-bottom: 1px solid #e5e5e5;

  /* Make the masthead heading the same height as the navigation */
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 40px;
    padding-bottom: 19px;
  }
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #333333;
  border-top: 1px solid #e5e5e5;
}

.container-narrow > hr {
  margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  .btn {
    font-size: 21px;
    padding: 16px 28px;
  }
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0;
  p + h4 {
    margin-top: 28px;
  }
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  .container {
    max-width: 1200px;
  }

  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-left: 0;
    padding-right: 0;
  }

  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }

  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}

.container {


  .navbar-header, .navbar-collapse {
      margin-left: -40px; margin-right: -40px;
    }

  @media screen and  (max-width: 991px) {
    padding: 0 20px;
  }

  @media screen and  (min-width: 992px) {
    padding: 0 0%;
  }
}

$font-family_oswald: Oswald;
$color_white: #fff;
$color_footer-blue: #0e4359;
$color_dark-blue: #1a3866;
$color_light-blue: #008ac9;
$color_input-border: #58a6cd;
$color_sky-blue: #c7f1ff;
$color_green: #7faf2b;
$color_dark-green: #7fae2b;
$color_standard-green: #48a200;
$color_brown: #ba8251;
$color_red: #eb1c24;
$color_red_hover: #da131b;
$color_logo-red: #ec1f24;
$color_box: #f1f5f8;
$color_light-box: #f1f5f7;
$color_grey-light: #6c8c9c;
$color_dark-grey: #4b5357;
$color_very-dark-grey: #333;
$color_almost-black: #000102;
$color_table-border: #b9c6cf;
$color_table-bg: #e9eef1;
$color_collapse-border: #d4dfe8;

$w479max: "max-width: 479px";
$w480: "min-width: 480px";
$w767max: "max-width: 767px";
$w768: "min-width: 768px";
$w991max: "max-width: 991px";
$w992: "min-width: 992px";
$w1199max: "max-width: 1199px";
$w1200: "min-width: 1200px";

$font-oswald: Oswald;
$color-white: #fff;

p { font-family: $font-oswald; }

h2 {
  margin: 0px;
  padding: 64px 0px 30px 0px;
  margin: 0px;
  font-family: $font-oswald;
  font-weight: 500;
  // text-transform: uppercase;
  color: #203868;

  @media screen and (max-width: 767px) {
    padding: 44px 0px 20px 0px;
    font-size: 36px; 
  }

  @media screen and (min-width: 768px) { 
    padding: 64px 0px 30px 0px;
    font-size: 36px; 
  }

  
}


// NAVIGACE + HEADER
.navbar-nav {
  li {
    font-family: $font-oswald;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
  }

  @media screen and (max-width: 991px) { 
    padding-right: 20px;
  }
}

.navbar-header { margin: 0px !important; max-width: 100%; margin-right: 0px; border-bottom: 0px solid #e74c3c; }
.navbar-default .navbar-nav > li > a:hover { text-decoration: none; }
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus { background-color: transparent; }
.navbar-default .navbar-toggle .icon-bar { background-color:  #203868; }
.navbar-default .navbar-toggle { margin: 0px; border-color:  #203868 !important; }
.navbar-inverse .navbar-collapse {  border-color: transparent;  }
.navbar-default .navbar-collapse { background-color: #fff; }

.navbar-default .navbar-collapse { 
  @media screen and (max-width: 768px) {
    border-top: none;
  }
}

.navbar-default .navbar-nav > li > a {
  color: #203868; 

  @media screen and (min-width: 992px) {
    padding-left: 36px; padding-right: 0px;
  }

  @media screen and (min-width: 992px) {
    padding-left: 48px; padding-right: 0px;
  }
}

.navbar-default .navbar-nav > li > a:hover { color: #1a2e55; }

.navbar { 
  height: 66px;
  border-bottom: none;
  background-color: #fff; 
}

.navbar-right { padding-top: 8px; }
.navbar-default .navbar-collapse { padding-left: 60px; text-align: left; }
.navbar-default .navbar-toggle { margin-top: 16px; }

.navbar-brand {
  padding-top: 0px;

  img {
    z-index: 5;
    position: absolute; 

    @media screen and (max-width: 767px) {
      height: 66px; 
      padding-left: 0px;
      margin-left: -20px;
    }

    @media screen and (min-width: 768px) {
      height: 96px; 
      margin-left: -24px;
    }

    @media screen and (min-width: 992px) {
      height: 140px; 
      margin-left: -71px;
    }

    @media screen and (min-width: 1200px) {
      height: 140px; 
      margin-left: -64px;
    }

    @media screen and (min-width: 1280px) {
      height: 140px; 
      margin-left: -78px;
    }

    @media screen and (min-width: 1400px) {
      height: 160px; 
      margin-left: -90px;
    }    

    @media screen and (min-width: 1600px) {
      margin-left: -96px;
    }
  }
}

.introduce-wrapper {
  

  @media screen and (max-width: 479px) { 
    background-color: rgba(18,131,218,.85);
  background: linear-gradient(rgba(20, 130, 180, 0.8), rgba(20, 130, 180, 0.5)), url('spotrebitelskymoot.cz/wp-content/uploads/2019/08/image-header-1.jpg') no-repeat center bottom; 
  background-position: top center;
    min-height: 480px; 
    background-size: auto 100%;  
  }

  @media screen and (min-width: 480px) { 
    background-color: rgba(18,131,218,.85);
  background: linear-gradient(rgba(20, 130, 180, 0.8), rgba(20, 130, 180, 0.5)), url('/wp-content/uploads/2019/08/image-header-1.jpg') no-repeat center bottom; 
  background-position: top center;
    min-height: 640px; 
    background-size: auto 100%;  
  }

  @media screen and (min-width: 768px) { 
    background-color: rgba(18,131,218,.85);
  background: linear-gradient(rgba(20, 130, 180, 0.8), rgba(20, 130, 180, 0.5)), url('/wp-content/uploads/2019/08/image-header-1.jpg') no-repeat center bottom; 
  background-position: top center;
    min-height: 520px; 
    background-size: cover;
    background-position: top center;
  }
}

.introduce {
  .title {
    font-family: $font-oswald;
    font-weight: 400;
    color: $color-white;
    
    @media screen and (max-width: 479px) {
      margin-left: -16px;
      padding: 100px 0px 0px 18px;
      font-size: 40px;
      line-height: 48px;
    }

    @media screen and (min-width: 480px) {
      margin-left: -16px;
      padding: 240px 0px 0px 18px;
      font-size: 40px;
      line-height: 48px;
    }

    @media screen and (min-width: 768px) {
      padding: 120px 0px 0px 20px;
      font-size: 44px; 
      line-height: 60px;
    }

    @media screen and (min-width: 992px) {
      padding: 164px 0px 0px 0px;
      font-size: 54px; 
      line-height: 78px;
    }

    @media screen and (min-width: 1200px) {
      padding: 164px 0px 0px 0px;
      font-size: 54px; 
      line-height: 78px;
    }

    @media screen and (min-width: 1600px) {
      padding: 164px 0px 0px 0px;
      font-size: 54px; 
      line-height: 78px;
    }
  }

}

  .sub-title {
    max-width: 700px;
    font-family: $font-oswald;
    font-weight: 300;
    color: $color-white;

    @media screen and (max-width: 767px) {
      padding: 10px 52px 10px 0px;
      font-size: 20px;
      line-height: 30px;
    }

    @media screen and (min-width: 768px) {
      padding: 10px 52px 10px 0px;
      font-size: 28px;
      line-height: 42px;
    }

    @media screen and (min-width: 992px) { 
      padding: 10px 52px 10px 0px;
    }

    @media screen and (min-width: 1200px) { 
      padding: 10px 52px 10px 0px;
    }
  }

.begin_wrapper {
  background-color: rgba(20, 130, 180, 0.1);
  padding-bottom: 64px;

  .begin_wrapper__introduce-text {
    display: inline-block; 
    text-align: justify;
    font-size: 16px;  
    color: #203868;

    @media screen and (min-width: 479px) { 
      padding-left: 30px; 
      padding-right: 30px;
    }

    @media screen and (min-width: 480px) { 
      padding-left: 36px; 
      padding-right: 36px; 
    }
    
    @media screen and (min-width: 768px) {
      padding-left: 10%; 
      padding-right: 10%;  
    }

    @media screen and (min-width: 992px) {
      padding-left: 10%; 
      padding-right: 10%;  
    }

    @media screen and (min-width: 1200px) {
      padding-left: 10%; 
      padding-right: 10%;  
    }
  }

  h2 { text-align: center; }

}

.get-it_wrapper {
  background-color: #203868;
  padding-bottom: 64px;

  h2 { text-align: center;
  color: #fff; }

  h4 {
    margin: 0px;
    padding: 18px 0px;
    font-size: 18px;
    padding-top: 44px;
    line-height: 26px;
    font-family: Arial;
    font-weight: 500;
    text-align: left;
    color: #fff;
  }

  .feature-text { 
    font-family: Arial;
    font-weight: normal;
    font-size: 16px;
    color: #333333; 
  }

}

.courses-wrapper {
  background-color: #fff;

  .want-compete-wrapper__text {
    display: inline-block; 
    padding-left: 20%; 
    padding-right: 20%; 
    font-size: 18px;
    text-align: justify;
    color: #333333;
  }

  h2 {
    text-align: center;
  }

  h3 {
    margin: 0px;
    font-size: 28px;
    padding: 54px 0px 0px;
    font-family: Oswald;
    font-weight: 500;
    text-align: center;
    padding-bottom: 26px;
  }

  h4 {
    margin: 0px;
    // padding: 18px 0px;
    font-size: 18px;
    // padding-top: 44px;
    font-family: Oswald;
    font-weight: 500;
    text-align: left;
    color: #333;
  }

  .course-title {
      padding-bottom: 26px !important;
    font-family: Oswald !important;
  font-size: 26px !important;
  color: #203868 !important;

   @media screen and (max-width: 767px) { 
      padding-left: 20px; 
      padding-top: 20px;
    }
  }

  .feature-text { 
    font-family: Arial;
    font-weight: normal;
    color: #333333; 
  }

  .feature-title { font-family: Oswald; }
}

.about-project_wrapper {
  margin-top: 0px;
  background-color: #fff;

  .introduce-text {
    padding-bottom: 52px;
    font-size: 16px; 
    text-align: justify;
    color: #203868;
    
    @media screen and (min-width: 479px) { 
      padding-left: 30px; 
      padding-right: 30px;
    }

    @media screen and (min-width: 480px) { 
      padding-left: 36px; 
      padding-right: 36px; 
    }
    
    @media screen and (min-width: 768px) {
      padding-left: 10%; 
      padding-right: 10%;  
    }

    @media screen and (max-width: 991px) {
      display: inline-block;
    }

    @media screen and (min-width: 992px) {
      display: flex;
      padding-left: 10%; 
      padding-right: 10%;  
    }

    @media screen and (min-width: 1200px) {
      padding-left: 10%; 
      padding-right: 10%;  
    }

    .image_wrapper {
      display: inline-block;

      img { 
        width: 180px; 
        height: auto; 
        
        @media screen and (max-width: 991px) { padding-top: 10px; }
      }
    }

    .text_wrapper {
      display: inline-block;
      
      @media screen and (max-width: 991px) { padding-top: 20px; }
      @media screen and (min-width: 992px) { padding-left: 36px; }
    }
  }

  h2 { text-align: center; }

  h3 {
    margin: 0px;
    padding: 0px 0px 10px 0px;
    font-size: 28px;
    font-family: Oswald;
    font-weight: 500;
    text-align: left;
    color: #555;
  }

  p {
    font-family: Arial;
    color: #222;
  }
}



.modal-content {
  h1 {
    margin: 0px;
    font-family: $font-oswald;
    font-weight: 700;
    font-size: 36px;
    color: #e3161b;
  }

  .sub-title {
    padding-bottom: 28px;
    font-family: $font-oswald;
    font-size: 28px;
    color: #3f4142;
  }

  h2 {
    padding-top: 36px; padding-bottom: 12px;
    font-family: $font-oswald;
    font-weight: 700;
    font-size: 28px;
    text-align: left;
    text-transform: none;
  }

  p {
    padding-top: 8px; padding-bottom: 8px;
    font-family: $font-oswald;
    font-size: 16px;
  }

  button {
    padding: 0px;
    background-color: #fff !important;
    font-size: 36px;
    color: #e3161b;
    text-align: right;
    opacity: 1;
  }

  ul, ol {
    padding-left: 16px;
    font-family: $font-oswald;
  }

  li {
    font-size: 16px;
    padding-bottom: 16px;
  }

  .perex {
    font-weight: 700;
  }

  .title-02 {
   padding-bottom: 48px;
  }
}

.modal-header { border-bottom: none; }

  .flex-container {
    margin-top: 22px;
    justify-content: center;
    @media screen and (max-width: 767px) { display: inline-block; }
    @media screen and (min-width: 768px) { display: inline-flex; }

    // header
    .homepage-topically__box-header {
      position: relative;
      height: 146px;
      width: 100%;
      margin: 0px;
      background-size: 100% auto;
      background-repeat: no-repeat;

      // nadpis
      .title {
        font-family: 'Oswald';
        position: absolute; 
        top: 38px; 
        left: 38px; 
        margin:0px; 
        padding: 0px;
        margin: 0px;
        font-weight: 500;
        font-size: 28px;
        color: white;
      }
    }




    
    div {
      position: relative;
      background-color: rgba(64, 160, 190, 0.1);
      text-align: center;

      // nadpis aktuálního kurzu nebo videokurzu
      .actual-homepage__course-title, .actual-homepage__videocourse-title {
        margin: 0px;
        font-family: Arial;
        font-size: 18px;
        text-align: left;
        text-transform: uppercase;

        @media screen and (max-width: 767px) { padding: 28px 0px 10px 28px; }
        @media screen and (min-width: 768px) { padding: 28px 0px 26px 38px; }
      }

      .homepage-topically__actual-course {
        background-color: transparent !important;
        width: 100%; 
        margin: 0px; 
        padding: 20px;
        font-size: 16px;
        text-align: justify;
        color: #333333;

        @media screen and (max-width: 399px) { display: inline-block; }
        @media screen and (min-width: 400px) { display: inline-flex;  }
 
        .text {
          width: 100%;
          margin: 0px 0px 48px; 
          padding: 20px 26px 16px 26px;
          text-align: justify;
          color: grey;

          @media screen and (max-width: 399px) { 
            display: inline-block;
            width: 100%; 
          }
        }
      }
    }

    .div--courses {
      @media screen and (max-width: 767px) {
        margin: 0px 0px 38px 0px; 
        padding-bottom: 80px;
        width: 100%;
      }
      @media screen and (min-width: 768px) { 
        margin: 0px 20px 0px 0px; 
        padding-bottom: 20px;
        width: 50%;
      }
    }

    .div--courses-modal {
      @media screen and (max-width: 767px) {
        margin: 0px 0px 38px 0px; 
        padding-bottom: 80px;
      }
      @media screen and (min-width: 768px) { 
        margin: 0px 20px 0px 0px; 
        padding-bottom: 20px;
      }
    }
  }

.courses-wrapper__table {
  display: inline;
  background-color: transparent !important;
  width: 100%;

  .date-box {
    @media screen and (max-width: 767px) {
      display: inline-block;
      min-height: 84px;
    }
  }

  .place-box {
    @media screen and (max-width: 767px) {
      display: inline-block;
      min-height: 66px;
    }
  }

  .price-box {
    @media screen and (max-width: 767px) {
      display: inline-block;
      min-height: 98px;
    }
  }

  .date-box, .place-box, .price-box {
    @media screen and (max-width: 767px) {
      display: inline-block;
    }

      
    @media screen and (min-width: 768px) { 
      display: block; 
    }
      
    width: 100%;
    height: 38px;
    background-color: transparent !important;
   
  }

  height: 40px;
  font-weight: 400;
  font-size: 16px;
}

.date:before, .capacity:before, .lecturer:before, .price-02:before, .place-02:before  {
      display: inline-block;
      width: 30px;
      line-height: 30px;
      background-repeat: no-repeat;
      background-size: auto 22px;
      content: "_"; color: transparent;
      background-position: top;
      margin-right: 6px;
    }

    .date-mobile:before, .lecturer-mobile:before, .lecturer-mobile:before, .price-02-mobile:before, .place-02-mobile:before  {

       @media screen and (max-width: 767px) {
        display: inline-block;
        width: 30px; // 36px
        line-height: 30px;
        background-repeat: no-repeat;
        background-size: auto 22px;
        content: "_"; color: transparent;
        background-position: left center;
      }
    }

.date:before, .date-mobile:before { background-image: url('https://i.dtest.cz/img/r/280.svg'); }
.price-02:before, .price-02-mobile:before { background-image: url('https://i.dtest.cz/img/r/285.png');  }
.place-02:before, .place-02-mobile:before { background-image: url('https://i.dtest.cz/img/r/281.svg');  }

// ikonky pozadím
.attribut { 
  font-family: Oswald;
  font-weight: 400;
  float: left;
  background-color: transparent !important;
  color:  #333333;

  @media screen and (max-width: 767px) { 
    float: left;
    display: block;
  }

    @media screen and (min-width: 768px) { 
      display: flex;
      float: left;
    }

  span {
    @media screen and (max-width: 767px) { 
      display: inline-table;
    }

    @media screen and (min-width: 768px) { 
      display: inline-table;
      text-align: left;
    }
  }
}

.btn {
  padding: 8px 12px;
  float: right;
  font-family: Oswald;
  font-weight: 500;
  color: #fff;
}

.btn:hover { color: #fff; }
.btn--blue { margin: 0px; font-size: 16px; background-color: #0387c8; }
.btn--red { margin: 0px; font-weight: 400; font-size: 16px; background-color: #008ac9; }
.btn--red:active, .btn--red:hover, .btn--red:focus, .btn--red:active { color: #fff; }

.btn--link {
  float: right;
  padding-right: 20px;
  padding-top: 30px;
}


// FOOTER: Navigace + odkazy na sociální sítě
.footer-navigation-wrapper { background-color: $color_footer-blue; }



.footer-navigation {
  @media screen and (max-width: 991px) { 
    padding-top: 24px;
    padding-bottom: 48px;
  }

  @media screen and (min-width: 992px) { 
    padding-top: 48px;
    padding-bottom: 48px;
  }

  // nadpisy nad odkazy (dTest, Služby, Vzdělávání)
  h6 {
    display: block;
    padding: 0px;
    font-family: $font-family_oswald;
    font-weight: 400;
    font-size: 22px;
    color: $color_white;

    @media screen and (max-width: 767px) { 
      margin: 0px; 
      padding-bottom: 0px;
    }

    @media screen and (min-width: 992px) {
      padding-bottom: 0px;
      margin: 0px;
    }
  }

  // odkazy navigace
  ul {
    padding-left: 0px;
    list-style-type: none;

    @media screen and (max-width: 767px) { padding-bottom: 38px; }

    li {
      padding-top: 4px;
      color: $color_white;

      a {
        fonf-family: Arial;
        font-size: 16px;
        text-decoration: underline;
        color: $color_white;
      }

      :hover { color: $color_sky-blue; }
    }
  }

  // Zůstaňte v kontaktu
  .contact-wrapper {
    @media screen and (max-width: 991px) {
      padding: 20px 40px 20px 40px;
    }

    @media screen and (min-width: 992px) {
      padding: 48px 0px 48px 0px;
    }

    .link {
      display: block;
      padding-bottom: 6px;
      font-size: 16px;
      text-decoration: underline;
      color: #fff;
    }

    .links {
      @media screen and (min-width: 992px) {
        margin-top: 10px;
      }
    }

    h6 {
      @media screen and (max-width: 991px) { margin: 0px; }

    }

    div {
      margin-bottom: 10px;

      @media screen and (max-width: 991px) { 
        display: blocks; 
        float: none;
      }

      @media screen and (min-width: 1200px) { 
        display: inline-block; 
        float: left;
        padding-left: 28px;
      }

      @media screen and (min-width: 1200px) { padding-left: 0px; }
      
      // nadpis
      h6 {
        display: block;
        margin: 0px;

      }

      // řádky s číslem a e-mailem
      div {
        span {
          display: inline-block;
          padding: 0px;
          font-family: $font-family_oswald;
          font-weight: 400;

          @media screen and (max-width: 479px) { width: 160px; font-size: 16px; }
          @media screen and (min-width: 480px) { width: 200px; font-size: 20px; }
          @media screen and (min-width: 768px) { width: 240px; }
        }

        a { color: $color_white; }

        // ikonka telefonu
        .phone:before {
          content: url('https://www.dtest.cz/data/images/91446.png');

          @media screen and (max-width: 479px) {  padding-right: 10px; }
          @media screen and (min-width: 480px) {  padding-right: 30px; }
        }

        // ikonka e-mailu
        .mail:before {
          content: url('https://www.dtest.cz/data/images/91445.png');
          
          @media screen and (max-width: 479px) {  padding-right: 10px; }
          @media screen and (min-width: 480px) {  padding-right: 30px; }
        }
      }

      // ikonky soc. sítí
      img {
        height: 42px;
        padding-bottom: 5px;
        padding-left: 16px;
      }
    }
  }
}


// FOOTER: Copyright
.footer-copyright {
  padding: 30px 0px 30px 0px;
  font-size: 12px;
  color: $color_grey-light;

  // levá část copyrightu
  div:first-of-type { text-align: left; }

  // pravá část copyrightu
  div:last-of-type {
    @media screen and (max-width: 767px) {
      padding-top: 20px;
      text-align: left;
    }

    @media screen and (min-width: 768px) { text-align: right; }
  }
}

.column {
  @media screen and (min-width: 992px) { padding-top: 48px; }
}


// SELFPROMOBOX: Kurzy pro podnikate
.case_wrapper {

  .selfpromobox_kurzy-pro-podnikatele_inner { 
    @media screen and ($w767max) { 
      margin: 0px;
      padding: 42px 0px 42px 0px; 
    }

    @media screen and ($w768) { 
      padding: 84px 0px 84px 0px; 
    }
    
      background: linear-gradient(
        rgba(64, 160, 190, 0.8), 
        rgba(64, 160, 190, 0.8)
      ), 
      url('https://www.dtest.cz/data/images/91455.png');
      background-size: cover;
      background-repeat: no-repeat;
      color: $color_white;

    .selfpromobox_kurzy-pro-podnikatele_inner__title {
      font-family: Oswald;
      font-weight: 500;
      font-size: 28px;
      display: block;
      text-align: center;

      @media screen and ($w479max) { padding: 0px 20px 27px 20px; }
      @media screen and ($w480) { padding: 0px 20px 28px 20px; }
    }

    .selfpromobox_kurzy-pro-podnikatele_inner__text {
      display: block;
      font-size: 16px;
      text-align: center;

      @media screen and ($w768) { padding-right: 50px; }
      
      a { color: #fff; text-decoration: underline}
    }

    .selfpromobox_kurzy-pro-podnikatele_inner__btn-wrapper {
      text-align: center;

      @media screen and ($w479max) { margin-top: 19px; }
      @media screen and ($w480) { margin-top: 38px; }
    }

    .more-link_wrapper {
      text-align: center;

      .more-link-02 {
        display: block; 
        padding-top: 10px; 
        color: #fff; 
        text-decoration: underline; 
        font-size: 16px;
      }
    }
  }
}


.newsletter-register-wrapper {


  @media screen and ($w479max) { background-size: 52%; }
  @media screen and ($w480) { background-size: 42%; }
  @media screen and ($w768) { background-size: 32%; }
  @media screen and ($w992) { background-size: 16%; }




      // formulář k přihlášení
      .form-inline {
        
        @media screen and ($w479max) {
          .btn { padding: 16px 19px; }
        }

        @media screen and ($w480) {
          .btn { 
            padding: 16px 38px;
            float: right; 
          }
        }

        .wrapper {
          display: inline-block; 
        border: 4px solid $color_white; 
        border-radius: 4px; 
        background-color: $color_white;

          .inner {
            padding-bottom: 20px;
            font-family: Arial;
            font-size: 16px;
            color: #fff;
            font-weight: bold;
          }
        }

         @media screen and ($w991max) {
          display: inline-block; 
          width: auto;
          margin-top: 26px;

        }

        @media screen and ($w992) { 
          display: inline-block; 
          width: auto;
          margin-top: 26px;
        }

        .form-group { 
          display: inline-block; 
          margin-bottom: 0px;

          input {
            line-height: 60px;
            height: 48px;

            @media screen and ($w479max) { max-width: 160px; }
            @media screen and ($w992) { min-width: 200px; }
            @media screen and ($w1200) { min-width: 300px; }
          }
        }

        input {
          font-size: 16px; 
          border: none; 
          box-shadow: none;
        }
  }


}

.fas { padding-right: 10px; }

.second-logo {
  @media screen and (min-width: 1600px) {
    position: absolute;
    margin-left: -50px;
  }
}

.course-content-wrapper {
  @media screen and ($w768) { 
    // padding-left: 10px;
    padding-right: 52px; 
  }

  @media screen and (max-width: 992px) { 
    padding-bottom: 36px; 
  }
  
  @media screen and (max-width: 768px) { padding: 0px 20px 0px 20px; }

  .panel { box-shadow: none; }

  // Obsah kurzu - JSF
  .course-content {
    // min-height: 320px;
    
    .panel-default {
      backround: none;
      border-top: none;
      border-right: none;
      border-left: none;
    }

    li {
      padding-left: 16px;
      font-family: Arial;
      font-size: 16px;
      text-align: left;
      color: #fff;
    }
  }

  .panel-title {
    text-align: left;

    h4 {
      margin: 0px;
      padding: 0px;
    }
  }

  .course-content .panel-default .panel-heading {
    padding: 14px 0px;
    background: none;
    border: none;
  }

  .course-content .panel-default .panel-heading .panel-title div {
    font-family: arial;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    text-decoration: none;
  }

  @media screen and (max-width: 479px) {
    .course-content .panel-default .panel-heading .panel-title div {
      display: inline-block;
      padding-right: 26px;
    }
  }

  @media screen and (min-width: 480px) {
    .course-content .panel-default .panel-heading .panel-title div {
      padding-right: 26px;
    }
  }

  .panel-heading { background-color: transparent; }

  .course-content .panel-default .panel-heading .panel-title div.collapsed { color: #333333; }


  @media screen and (max-width: 767px) {
    .course-content .panel-default .panel-heading .panel-title div:before { right: 42px; }
  }

  @media screen and (min-width: 768px) {
    .course-content .panel-default .panel-heading .panel-title div:before { right: 70px; }
  }

  .course-content .panel-default:last-of-type { border: none; }

  .course-content ul {
    padding-left: 0px;
    list-style-type: none;
  }

  .course-content ul li {
    padding-bottom: 16px;
    color: #333333;
  }

  .course-content .panel-default .panel-heading .panel-title div:before {
    font-family: 'Font Awesome 5 Free';
    content: "\f106";
    display: inline-block;
    position: absolute;
    font-size: 16px;
    color: #333333;
    width: 10px;
  }

  .course-content .panel-default .panel-heading .panel-title div.collapsed:before { 
    font-family: 'Font Awesome 5 Free';
    content: "\f107";
  }


  .course-content ul li:before {
    font-family: Arial;
    content: ">";
    font-size: 12px;
    padding-right: 10px;
  }

  .panel-default > .panel-heading + .panel-collapse > .panel-body { border-top: none; }
}

.modal-dialog {
  width: 100%;
  margin: 0px;
}

.modal-content {
  background-color: rgba(255,255,255,.85);
}




.course-content-wrapper .course-content ul li { 
  background-color: transparent !important;
}

.course-content-wrapper .course-content .panel-default .panel-heading {
  background-color: transparent !important;
}

.course-content-wrapper .course-content .panel-default {
background-color: transparent !important;
}

.jury_wrapper {
  margin-top: 52px; 
  padding-bottom: 52px;
  background-color: rgba(20, 130, 180, 0.1); 

  h2 { text-align: center; }

  .inner {
    display: inline-block;  
    font-size: 16px; 
    color: #333;

    @media screen and (min-width: 992px) {
      padding-left: 10%;
      padding-right: 10%;
    }

    img {
      @media screen and (max-width: 767px) {
        width: 100%; 
      }

      @media screen and (min-width: 768px) {
        height: 200px; 
        margin-right: 40px;
        float: left;
        border-radius: 100%;
      }
    }

    .text_wrapper { padding: 26px 0px 0px 0px; }

    .name {
      font-family: Oswald; 
      font-weight: 500; 
      font-size: 18px; 
      line-height: 27px;
      color: #203868;
    }

    .position {
      font-size: 16px; 
      color: #777;
    }

    .description { 
      font-family: Arial;
      padding-top: 6px; 
    }
  }
}

.odber-novinek_title {
  @media screen and (max-width: 992px) {
    padding-top: 36px;
  }
}


.partners_wrapper {
  padding-bottom: 64px;
  background-color:: #E7F2F7;

  h2 { text-align: center; }

  .row_custom {
    margin: 0px auto;
    // max-width: 1300px;

    @media screen and (min-width: 768px) {
      // padding-left: 4%; padding-right: 4%;
    }

    .column {
      display: inline-flex;

      @media screen and (max-width: 479px) {
        width: 100%;
        padding-bottom: 36px;
      }

      @media screen and (min-width: 480px) {
        width: 48%;
        padding-bottom: 36px;
      }
      
      @media screen and (min-width: 992px) {
        // padding-left: 20px;
        width: 19%;
      }

      a {
        display: inline-block;
        margin: 0px auto;

        img {
          padding-left: 14px; 
          padding-right: 14px; 
          text-align: center;
        }
      }

      .logo {
        @media screen and (max-width: 991px) { height: 72px; }
        @media screen and (min-width: 992px) { height: 50px; }
        @media screen and (min-width: 1200px) { height: 72px; }
      }
    }
  }
}

.course-item { 
  display: flex; 
  background-color: #E7F2F7;
}

.course-item__image-01 { 
  @media screen and ( max-width: 991px ) {
    display: none;
  }

  @media screen and ( min-width: 992px ) {
    flex: 1;
    background-image: url('https://www.dtest.cz/data/images/101464.jpg');
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}

.course-item__image-02 { 
  @media screen and ( max-width: 991px ) {
    display: none;
  }

  @media screen and ( min-width: 992px ) {
    flex: 1;
    background-image: url('https://www.dtest.cz/data/images/91453.jpg');
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}

.course-item__image-03 { 
  @media screen and ( max-width: 991px ) {
    display: none;
  }

  @media screen and ( min-width: 992px ) {
    flex: 1;
    background-image: url('https://www.dtest.cz/data/images/101463.jpg');
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}

.course-item__content { 
  flex: 2; 
  padding: 30px;

  h3 {
    margin: 0px; 
    line-height: 32px; 
    text-align: left; 
    color: #203868;
    padding-top: 0px; 

    @media screen and ( max-width: 479px ) { font-size: 18px; }
    @media screen and ( min-width: 480px ) { font-size: 24px; }
  }

  p {
    @media screen and ( max-width: 767px ) { padding-top: 0px; padding-bottom: 16px; }
    @media screen and ( min-width: 768px ) { padding-top: 18px; }


    margin-bottom: 16px;
    font-family: Arial;
    font-size: 16px;
  }
}


// Custom zaškrtávátka - obecný wrapper
.custom-checkbox_wrapper {
  margin-top: 20px;

  .checkbox input:after, .radio input:after {
    content: '';
    display: table;
    clear: both;
  }

  .checkbox .cr, .radio .cr {
      position: relative;
      display: inline-block;
      border: 1px solid $color_white;
      border-radius: 2px;
      width: 18px;
      height: 18px;
      float: left;
      margin-right: .5em;
      background: $color_white;
      margin-right: 16px;
  }

  .cr {     
  }

  .radio .cr { border-radius: 50%; }

  .checkbox .cr .cr-icon, .radio .cr .cr-icon {
      position: absolute;
      font-size: 10px;
      line-height: 0;
      top: 50%;
      left: 20%;
      color: #092542;
  }

  .radio .cr .cr-icon { margin-left: 0.04em;}

  .checkbox label input[type="checkbox"], .radio label input[type="radio"] { display: none; }

  .checkbox label input[type="checkbox"] + .cr > .cr-icon,
  .radio label input[type="radio"] + .cr > .cr-icon {
      transform: scale(3) rotateZ(-20deg);
      opacity: 0;
      transition: all .0s ease-in;
  }

  .checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
  .radio label input[type="radio"]:checked + .cr > .cr-icon {
      transform: scale(1) rotateZ(0deg);
      opacity: 1;
  }

  .checkbox label input[type="checkbox"]:disabled + .cr,
  .radio label input[type="radio"]:disabled + .cr {
      opacity: .5;
  }

  .checkbox { @media screen and ($w767max) { 
      display: inline-block;
      margin-bottom: 0px; 
    } 
  }

  label { 
    font-weight: normal;

    @media screen and ($w767max) { padding-left: 6px; }
    @media screen and ($w768) { padding-left: 0px;  }

    a { 
      text-decoration: underline;
      color: $color_white; 
    }
  }

  .checkbox {
    @media screen and ($w767max) { display: block; }
  }
}

.panel-title {
  div { 
    cursor: pointer;
  }
}

.error { 
  padding: 10px 0px 20px 0px;
  color: red;
}

.newsletter-subscription {
  h3 {
    font-family: Oswald; 
    font-weight: 400; 
    font-size: 22px; 
    margin: 0px; 
    padding-bottom: 16px;
  }

  .second {
    @media screen and (max-width: 991px) { padding-top: 36px; }
  }


  span {
    font-size: 16px;
  }
}

.feature_wrapper {
  display: flex;
  margin-top: 44px;

  .feature-icon {
    display: inline-block;
    min-width: 52px;

    .fas { color: #fff; }
  }

  .feature-title {
    display: inline-block;
    font-size: 16px;

    @media screen and (max-width: 767px) { padding: 6px 0px 0px 18px; }
    @media screen and (min-width: 768px) { padding: 6px 40px 0px 18px; }
  }
}

.custom-collapse {
  height: 0;
  overflow: hidden;
}
.custom-collapse.in {
  height: auto;
}

.heading {
  text-align: center;

  h2 {
    display: inline-block;
  }
}

#smaller-border {
  height: 4px;
  width: 54%;
  margin: -0.75rem auto 0 auto;
  background-color: #203868;
  margin-top: 15px;
}

#footer-border {
  display: block;
  height: 4px;
  width: 30px;
  margin: 6px 0px 0px 0px;
  padding-bottom: 0px;
  background-color: #fff;
}

hr {
  margin-top: 6px;
  margin-bottom: 6px;
}

.btn-custom-collapse.opened:after {
  padding-left: 6px;
  font-family: 'Font Awesome 5 Free';
  content: "\f106";
  font-weight: 700
}

.btn-custom-collapse:after {
  padding-left: 6px;
  font-family: 'Font Awesome 5 Free';
  content: "\f107";
  font-weight: 700
}

.attribut-title {
  display: inline-block;
  min-width: 108px;
}

iframe { min-height: 640px !important; }

.attribut-info {
  @media screen and (max-width: 767px) { display: block; }
  @media screen and (min-width: 768px) { display: inline-block; }

}

.reservation-wrapper {
  @media screen and (max-width: 767px) { padding: 0px 0px 0px 0px; }
  @media screen and (min-width: 768px) { padding: 52px 40px 30px 40px; }
}

.mobile-break {
  @media screen and (max-width: 499px) { 
   display: initial !important;
  }

  @media screen and (min-width: 500px) { 
   display: none;
  }
}

.form-sent {
  padding-top: 16px;
  font-family: Arial;
  font-weight: bold;
  font-size: 18px;
}

.download-text {
  @media screen and (max-width: 767px) { padding: 0px 20px 0px 20px; }
  @media screen and (min-width: 768px) { padding: 0px }
}


.download-link {
  @media screen and (max-width: 767px) { padding: 0px 20px 30px 20px; }
  @media screen and (min-width: 768px) { padding: 0px }
}


/* -----------------
 * MOOT COURT 
 * ----------------- */

.case_wrapper__case-paragraph { 
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #203868;
}

.case_wrapper__documents { 
  margin-top: 20px; 
  padding: 30px; 
  border: 1px solid #203868;
}

.case_wrapper__document-title {
  margin-top:0px;
  font-family: Oswald;
  font-size: 24px;
  color: #203868;
}

.case_wrapper__document-file {
  display: block;
  font-family: Arial;
  font-size: 16px;
  color: #203868;
}

.case_wrapper__document-file:before {
  content: " ";
  display: inline-block;
  background-image: url(https://www.znackakvalitydtest.cz/domains/znackakvalitydtest.cz/images/ico-document.png);
  width: 40px;
  height: 30px;
  background-repeat: no-repeat;
}

.case_wrapper__image {
  width: 100%; 
  @media screen and (max-width: 991px) { padding-top: 20px;  }
  @media screen and (min-width: 992px) { padding-top: 0px;  }

}

.jury_wrapper .juryman {
  @media screen and (max-width: 991px) { margin-bottom: 30px;  }
}

.jury_wrapper__row {
  font-size: 16px;

  @media screen and (min-width: 992px) { padding-top: 40px;  }
}

.jury_wrapper__juryman-image {
  float: left; 
  width: 120px; 
  height: 120px; 
  margin-right: 20px; 
  border-radius: 100%;
}

.jury_wrapper__juryman-text {
  padding-right: 100px;
}

.terms_wrapper {
  .step {
    position: relative;
    padding: 20px;
    background: #fff;

    @media screen and (max-width: 991px) { 
      display: block;
      width: 100%;
      height: auto;
      margin: 0px 0px 50px 0px;
    }

    @media screen and (min-width: 992px) { 
      display: inline-block;
      width: 30%;
      height: 330px;
      margin: 0px 30px 0px 30px;
    }

    @media screen and (min-width: 1200px) { 
      height: 290px;
    }

    h3 {
      padding-bottom: 16px;
      font-family: Oswald;
      font-weight: 500;
      color: #203868;
    }

    span {
      font-size: 16px;
      color: #333333;
    }
  }

  .number {
    position: absolute;
    width: 56px; 
    height: 56px; 
    padding: 8px 0px 0px 20px;
    font-family: Oswald;
    font-weight: 500;
    font-size: 24px;
    border: 3px solid #1a3866; 
    border-radius: 100%;
    background-color: #1a3866;
    color: #fff;
  }

  .number-1, .number-2, .number-3  {
      top: -28px;
      left: -24px;  
    
  }
}

.terms_wrapper__inner {
  position: relative; 
  padding-bottom: 64px;

  @media screen and (max-width: 991px) { 
    margin-left: 40px;
    margin-right: 40px;
  }

  @media screen and (min-width: 992px) { display: flex; }
}

.form-prize_input { 
  display: block;

  input { margin-right: 6px; }
}

.form-prize_wrapper { 
  margin-top: 30px;
  text-align: center;
}

.btn_red {
  padding: 20px 60px;
  font-family: Oswald;
  font-size: 21px;
  text-align: center;
  border-radius: 3px;
  background: #eb1c24;
  color: #fff;
  border: none;
}

.form_wrapper {
  padding-bottom: 48px;
  color: #fff;
  background-color: #205469;

  a { 
    text-decoration: underline;
    color: #fff;
  }

  h3 { 
    padding: 20px 0px 10px 0px;
    font-family: Oswald;
    font-weight: 500;
    font-size: 18px;
  }

  .add-student { 
    font-weight: bold;
    text-decoration: underline;
  }
}

.required { 
  color: red;
}